import {useEffect, useState, useContext} from 'react';
import {Row, Col, Card, Button, Form, Modal} from 'react-bootstrap';
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import Swal from 'sweetalert2';
import Select from 'react-select';
import {useHistory} from 'react-router-dom';
import axios from '../../../services/axios';
import moment from 'moment';
import {ConfigContext} from '../../../contexts/ConfigContext';
import {ESPIONAR_SEFAZ, TIPOCONSULTA, SEQPESQNFE, UPDATEDATAINI} from '../../../store/actions';
import procurando2 from '../../../assets/images/procurando3.gif';
import noData from '../../../assets/images/no-data.png';
import {TrataDataIniEmpresas, DownloadCloudMonitor} from '../../../components/Utilitarios/Funcoes';
import OpcoesNfe from './nfe/index';
import OpcoesCte from './cte/index';
import OpcoesNfse from './nfse';

import addDays from 'date-fns/addDays';

// BUG: Verificar sweetAlert para o response.data 'nao encontrou', pois não retorna nada para CT-e

/**
 *
 *
 * @param {*} props showModalEspionar | setShowModalEspionar
 * @return {*}
 */

const stepsRide = [
  {
    content: <h5>Vamos Espionar os primeiros documentos!</h5>,
    placement: 'center',
    locale: {skip: <strong aria-label='skip'>Pular</strong>, next: 'Vamos lá!', back: 'Cancelar'},
    target: '.modal-body',
  },
  {
    target: '.tipoDoc',
    content: 'Selecione o tipo de documento que deseja consultar',
    locale: {skip: <strong aria-label='skip'>Pular</strong>, next: 'Próximo', back: 'Voltar'},
    placement: 'top',
  },
  {
    target: '.tour-2',
    content: 'Clique aqui para consultar e armazenar os documentos localizados',
    locale: {skip: <strong aria-label='skip'>Pular</strong>, last: 'Finalizar', back: 'Voltar'},
  },
];

const ModalEspionar = () => {
  const configContext = useContext(ConfigContext);
  const {dispatch} = configContext;
  //Primeira consulta
  const [enablePesquisar, setEnablePesquisar] = useState(false); // Hablita o botao para consultar
  const [tipoDocCons, setTipoDocCons] = useState('-1'); //-1 = Nenhum, 0 = NFE, 1 = CTE, 2 = NFSE
  const [bgSelectNfe, setBgSelectNfe] = useState(''); // Background do card NFE
  const [bgSelecCte, setBgSelectCte] = useState(''); // Background do card CTE
  const [bgSelectNfse, setBgSelectNfse] = useState(''); // Background do card NFSE
  const [rbManif, setRbManif] = useState('0'); // 0 = Não manifestar, 1 = Ciência da Operação
  const [rbPeriodo, setRbPeriodo] = useState(90); // 90 = 90 dias, 60 = 60 dias, 30 = 30 dias
  const [rbPeriodoCte, setRbPeriodoCte] = useState(30); // 90 = 90 dias, 60 = 60 dias, 30 = 30 dias
  const [rbTomador, setRbTomador] = useState('0'); // 0 = Apenas Tomador, 1 = Todas as participações
  const [rbNsu, setRbNsu] = useState(0); // 0 = Último NSU, 1 = A partir do NSU
  const [rbNsuCte, setRbNsuCte] = useState(0); // 0 = Último NSU, 1 = A partir do NSU
  const [rbNsuNfse, setRbNsuNfse] = useState(0); // 0 = Último NSU, 1 = A partir do NSU
  const [selectEmpresa, setselectEmpresa] = useState([0]); //Options do SELECT empresas
  const [empselecionada, setempselecionada] = useState([]); // Empresa selecionada
  const [txtNsuValue, setTxtNsuValue] = useState(''); // NSU digitado
  const [txtNsuValueCte, setTxtNsuValueCte] = useState(''); // NSU digitado
  const [txtNsuValueNfse, setTxtNsuValueNfse] = useState(''); // NSU digitado
  const [tipoCertificado, setTipoCertificado] = useState(0); // 0 = A1, 1 = A3
  const [espionando, setEspionando] = useState(false); // Espionando
  const [primeiroLoad, setPrimeiroLoad] = useState(true); // Primeira vez que carrega
  const [rbNFSe, setRbNFSe] = useState(-1); // 0 = nacional 1 = municipal
  const [arrayPermiteEspionar, setArrayPermiteEspionar] = useState({nfe: 't', cte: 't', nfse: 't'}); // Array de empresas que permite espionar
  const [arrayPermiteEspionarConta, setArrayPermiteEspionarConta] = useState({nfe: 't', cte: 't', nfse: 't'}); // Array de empresas que permite espionar
  const [inscricaoMunicipal, setInscricaoMunicipal] = useState(''); // Inscrição municipal
  const [periodoNfse, setPeriodoNfse] = useState([new addDays(new Date(), -15), new Date()]); // Período de pesquisa NFSe
  const [tipoServico, setTipoServico] = useState({value: '1', label: 'Prestados', isDisabled: false}); // Tipo de serviço NFSe
  const sessiosS = JSON.parse(base64_decode(sessionStorage.getItem(`ddesp`)));
  const uid = sessiosS[3];
  const strg = localStorage.getItem(`xpaccss${base64_encode(uid)}`);
  const storageGeral = JSON.parse(base64_decode(sessionStorage.getItem(`ddesp`)));
  const arrStrg = JSON.parse(base64_decode(strg));
  const [selectedMunicipio, setSelectedMunicipio] = useState([{value: -1, label: 'Carregando Municípios disponíveis'}]);

  const [dataHoraNfe, setDataHoraNfe] = useState(''); // data e hora da ultima consulta nfe
  const [dataHoraCte, setDataHoraCte] = useState(''); // data e hora da ultima consulta Cte
  const [dataHoraNfse, setDataHoraNfse] = useState(''); // data e hora da ultima consulta Nfse
  const [blqInterno, setBlqInterno] = useState(false); // Bloqueio interno
  const espionarA3 = [
    <Row>
      <Col
        sm={12}
        className='text-center'
      >
        <h5>Certificado A3</h5>
        <p className='mb-0'>Para realizar a consulta, é necessário que você instale o Espião Monitor.</p>
        <p>Após a instalação, basta iniciar com seus dados e realizar a consulta.</p>
        <small>Para uma experiência melhor, recomendamos o uso do certificado A1!</small>
        <br />
        <Button
          variant='primary'
          className='rounded btn-sm mt-1 tour-2'
          onClick={() => DownloadCloudMonitor()}
        >
          Download Espião Monitor
        </Button>
      </Col>
    </Row>,
  ];

  const [showOptConsulta, setShowOptConsulta] = useState(!arrStrg['possuiNotas']);
  let history = useHistory();
  localStorage.setItem('tipoTeste', 1);

  /* Ao mudar a empresa selecionada */
  const handleChangeEmpresa = (e) => {
    
    setempselecionada(e);
    if (e.tipo !== null) {
      setTipoCertificado(e.tipo);
    } else {
      setTipoCertificado(-1);
    }
    setArrayPermiteEspionar({nfe: e.espionar_nfe, cte: e.espionar_cte, nfse: e.espionar_nfse});

    setDataHoraNfe(e.ultima_consulta_nfe);
    setDataHoraNfse(e.ultima_consulta_nfse);
    setRbTomador(e.opcao_consulta_cte);
    setInscricaoMunicipal(e.inscricao_municipal);
    setBlqInterno(e.bloqueio_interno);
    if (e.ultima_consulta_cte_s === null) {
      setDataHoraCte(<span className='text-danger'>Nunca consultado</span>);
    } else {
      setDataHoraCte(e.ultima_consulta_cte_s);
    }

    localStorage.setItem('empDoc', base64_encode(JSON.stringify(e)));
  };

  const handleTipoDoc = (e) => {
    setTipoDocCons(e.target.value);
    if (e.target.value === '0') {
      setBgSelectNfe('bg-success text-white');
      setBgSelectCte('');
      setBgSelectNfse('');
    } else if (e.target.value === '1') {
      setBgSelectNfe('');
      setBgSelectCte('bg-success text-white');
      setBgSelectNfse('');
    } else if (e.target.value === '2') {
      setBgSelectNfe('');
      setBgSelectCte('');
      setBgSelectNfse('bg-success text-white');
    }
  };

  // cont para teste do alerta

  const d = '02/05/2024 12:00:00'; // Data da NFe 60 dias atrás
  // Calcular a diferença de dias da última consulta
  const dataNfe = dataHoraNfe !== null && dataHoraNfe !== '' ? dataHoraNfe.split(' ')[0] : null;

  const dataNfeMoment = moment(dataNfe, 'DD/MM/YYYY');

  const dataAtual = moment(); // Usar a data atual
  const difNfe = dataAtual.diff(dataNfeMoment, 'days');

  const setAlertNfe = () => {
    // Função para exibir alerta se não ocorreu consulta a mais de 60 dias
    if (difNfe > 60 || dataHoraNfe === null || dataHoraNfe === '' || Number(empselecionada.maior_nsu_nfe) === 0) {
      return (
        <div className='p-1'>
          <p className='mb-0'>
            <span className='mdi mdi-alert text-warning mr-1 f-16'></span>
            <strong>Aviso</strong>
          </p>
          <small className='p-0'>
            A SEFAZ pode não liberar os documentos de períodos anteriores.
            <a
              href='https://www.espiaonfe.com.br/lab/quais-sao-as-regras-para-consultar-nfe-na-sefaz/'
              target='_blank'
            >
              Saiba mais
            </a>
            .{' '}
          </small>
        </div>
      );
    }
  };
  // useEffect(() => {
  //   setAlertNfe();
  // }, [dataHoraNfe]);

  /**
   * Consulta documentos na SEFAZ ou prefeituras
   *
   * TipoDoc = 0 = NFE
   * TipoDoc = 1 = CTE
   * TipoDoc = 2 = NFSE
   *
   * @return {*}
   */
  const handleEspionar = async () => {
    if (tipoDocCons !== 2 && tipoDocCons !== '-1') {
      if (empselecionada.value === 0) {
        dispatch({type: ESPIONAR_SEFAZ, espionarSefaz: false});
        Swal.fire({
          title: 'Ops!',
          icon: 'error',
          html: 'Selecione uma empresa para continuar.',
        }).then(() => {
          dispatch({type: ESPIONAR_SEFAZ, espionarSefaz: true});
        });
      } else {
        //=====================================================NFE
        if (tipoDocCons === '0') {
          setEspionando(true);
          setEnablePesquisar(false);
          //dispatch({type: ESPIONAR_SEFAZ, espionarSefaz: false});
          const dataPost = [rbPeriodo, rbManif, rbNsu, txtNsuValue, empselecionada.cnpj_cpf];

          const dataUltima = moment(empselecionada.ultima_consulta);

          const ultimaConsulta = dataUltima.format('HH:mm');
          dataUltima.add(1, 'hour');
          dataUltima.add(1, 'minute');

          const agora = moment();
          let break_ = false;

          if (agora.isBefore(dataUltima)) {
            //dispatch({type: ESPIONAR_SEFAZ, espionarSefaz: false});
            await Swal.fire({
              title: 'Alerta de consulta recente',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#23bd9e',
              confirmButtonText: 'Sim',
              cancelButtonText: 'Não',
              target: '.modal-body',
              html: `Foi realizada recentemente (menos de 1h atrás) uma consulta para essa empresa às ${ultimaConsulta}.<br/><br/>

                      <span class="f-w-600">RECOMENDAÇÃO</span><br/>
                      Fazer nova consulta a partir das ${dataUltima.format('HH:mm')}<br/><br/>

                      Deseja aguardar o horário recomendado?`,
            }).then((result) => {
              if (result.isConfirmed) {
                setEspionando(false);
                setEnablePesquisar(true);
                break_ = true;
              }
            });
          }
          if (break_ === true) {
            return;
          }

          axios
            .post('/api/consultas/', {dest: 'espionarA1', dataPost})
            .then((response) => {
              const response_ = response.data;

              if (response_ !== 'nao encontrou') {
              }
              //000 = Certificado vencido
              //001 = Certificado não localizado
              //002 = Erro ao ler certificado digital

              //003 = Erro de comunicação com a SEFAZ
              //004 = Erro não catalogado

              //006 = Erro de comunicação da SEFAZ
              //007 = Problemas ao ler retorno da SEFAZ

              //137 = Não localizou novos documentos
              //138 = Foram encontrados novos documentos
              //656 = A consulta retornou consumo indevido
              //593 = CNPJ-Base consultado difere do CNPJ-Base do Certificado Digital
              const codigoRet = response_.cod;
              const motivoRet = response_.motivo;
              const seq = response_.seq;

              let textoBotaoRedirect = '';  // Texto do botão de redirecionamento
              let msgAdicional = '';       // Mensagem adicional
              let linkRedirect = '';      // Link de redirecionamento
              let iconSwal = '';         // Icone do sweetalert
              let titleSwal = '';  // Titulo do sweetalert
              let linkConsumoIndevido = 'https://www.espiaonfe.com.br/lab/consumo-indevido-conheca-as-regras-de-consulta-nfe-da-sefaz/'; // Link de consumo indevido
              let linkSaibaMais = 'https://www.espiaonfe.com.br/lab/quais-sao-as-regras-para-consultar-nfe-na-sefaz/'; // Link de saiba mais
              let linkCertificadoDigital = 'https://www.espiaonfe.com.br/lab/?i=o-que-e-certificado-digital-e-para-que-serve-conheca-as-principais-duvidas'; // Link de certificado digital
            
              let ExibeRedrecionar = false; // Exibe ou nao botão de redirecionamento

              let existeHistoricoNfe = false; // verifica se existe histórico de nfe
              let existeMenuCertificados = false; // verifica se existe menu de certificados
              let existeMenuEmpresas = false; // verifica se existe menu de empresas

              if (configContext.state.menuUsuario.length > 0) {
              } else {
                const naviItens = storageGeral[10];
                naviItens[0].items.forEach((item) => {
                  if (item.id === '4') {
                    if (item.children) {
                      const findCertificados = item.children.find((item) => item.id === '31'); //Menu certificados
                      const index = item.children.indexOf(findCertificados);

                      const findEmpresas = item.children.find((item) => item.id === '13');
                      const indexE = item.children.indexOf(findEmpresas);
                      if (indexE > -1) {
                        existeMenuEmpresas = true;
                      }
                      if (index > -1) {
                        existeMenuCertificados = true;
                      }
                    }
                  } else if (item.id === '12') {
                    if (item.children) {
                      const findHistNfe = item.children.find((item) => item.id === '3');
                      if (findHistNfe) {
                        existeHistoricoNfe = true;
                      }
                    }
                  }
                });
              }

              if (codigoRet === '000') {
                iconSwal = 'error';
                titleSwal = 'Ops!';
                linkRedirect = '/certificados';
                msgAdicional = `
                <p class='f-18'>${motivoRet}</p>
                <small><a href=${linkCertificadoDigital} target="_blank">Saiba mais sobre o certificado digital aqui.</a></small>`;
                textoBotaoRedirect = existeMenuCertificados === true ? 'Gerenciar Certificados' : '';
              } else if (codigoRet === '001') {
                iconSwal = 'error';
                titleSwal = 'Ops!';
                linkRedirect = '/certificados';
                msgAdicional = `
                <p class='f-18'>${motivoRet}</p>
                <small><a href=${linkCertificadoDigital} target="_blank">Saiba mais sobre o certificado digital aqui.</a></small>`;
                textoBotaoRedirect = existeMenuCertificados === true ? 'Gerenciar Certificados' : '';
              } else if (codigoRet === '002') {
                iconSwal = 'error';
                titleSwal = 'Ops!';
                linkRedirect = '/certificados';
                msgAdicional = `
                <p class='f-18'>${motivoRet}</p>
                <small><a href=${linkCertificadoDigital} target="_blank">Saiba mais sobre o certificado digital aqui.</a></small>`;
                textoBotaoRedirect = existeMenuCertificados === true ? 'Gerenciar Certificados' : '';
              } else if (codigoRet === '003') {
                iconSwal = 'error';
                titleSwal = 'Ops!';
                linkRedirect = '/history/nfe';
                msgAdicional = `<small>
                <p class='f-18'>${motivoRet}</p>`;
                textoBotaoRedirect = existeHistoricoNfe === true ? 'Ver no Histórico NF-e' : '';
              } else if (codigoRet === '004') {
                iconSwal = 'error';
                titleSwal = 'Ops!';
                linkRedirect = '/history/nfe';
                msgAdicional = `
                <p class='f-18'>Ocorreu um problema inesperado, tente novamente daqui a alguns minutos!</p>
                <small><a href=${linkSaibaMais} target="_blank">Saiba mais sobre a consulta aqui.</a></small>`;
                textoBotaoRedirect = existeHistoricoNfe === true ? 'Ver no Histórico NF-e' : '';
              } else if (codigoRet === '006') {
                iconSwal = 'error';
                titleSwal = 'Ops!';
                linkRedirect = '/history/nfe';
                msgAdicional = `
                <p class='f-18'>${motivoRet}</p>`;
                textoBotaoRedirect = existeHistoricoNfe === true ? 'Histórico de NF-e' : '';
              } else if (codigoRet === '007') {
                iconSwal = 'error';
                titleSwal = 'Ops!';
                linkRedirect = '/history/nfe';
                msgAdicional = `
                <p class='f-18'>${motivoRet}</p>`;
                textoBotaoRedirect = existeHistoricoNfe === true ? 'Histórico de NF-e' : '';
              } else if (codigoRet === '137') {
                ExibeRedrecionar = true;
                let mensagemAdicional = '';
                if (difNfe > 60 || dataHoraNfe !== null || dataHoraNfe !== '' || Number(empselecionada.maior_nsu_nfe) === 0) {
                  mensagemAdicional = `
                   <div class='p-1'>
                    <p class='mb-0'>
                      <span class='mdi mdi-alert text-warning mr-1 f-16'></span>
                      <strong>Aviso</strong>
                    </p>
                    <small class='p-0'>
                      A SEFAZ pode não liberar os documentos de períodos anteriores.
                      <a
                        href='https://www.espiaonfe.com.br/lab/quais-sao-as-regras-para-consultar-nfe-na-sefaz/'
                        target='_blank'
                      >
                        Saiba mais
                      </a>
                      
                    </small>
                  </div>
                  `;
                }

                iconSwal = 'error';
                titleSwal = 'Ops!';
                linkRedirect = '/history/nfe';
                msgAdicional = `
                <p class='f-18'>${motivoRet}</p>
                ${mensagemAdicional}`;
                textoBotaoRedirect = existeHistoricoNfe === true ? 'Ver no Histórico NF-e' : '';
              } else if (codigoRet === '138' || motivoRet.indexOf('encontrou novos') > -1) {
                ExibeRedrecionar = true;
                iconSwal = 'success';
                titleSwal = 'Sucesso!';
                linkRedirect = '/history/nfe';
                msgAdicional = `
                <p class='f-18'>Encontramos novos documentos na SEFAZ! 🚀📄</p>`;
                textoBotaoRedirect = existeHistoricoNfe === true ? 'Ver no Histórico NF-e' : '';
              } else if (codigoRet === '656') {
                iconSwal = 'warning';
                titleSwal = 'Alerta de consulta recente';
                msgAdicional = `
                    <p class='f-18'>
                          Uma consulta foi feita para esta empresa na SEFAZ há menos de 1 hora.
                          Tente novamente em 60 minutos</p>
                    <small><a href=${linkConsumoIndevido} target="_blank">Saiba mais sobre o consumo indevido aqui.</a></small> `;
                textoBotaoRedirect = existeHistoricoNfe === true ? 'Histórico de NF-e' : '';
                linkRedirect = '/history/nfe';
              } else if (codigoRet === '593') {
                iconSwal = 'error';
                titleSwal = 'Ops!';
                msgAdicional = `
                    <p class='f-18'>${motivoRet}</p>`;
                linkRedirect = '/history/nfe';
                textoBotaoRedirect = existeHistoricoNfe === true ? 'Histórico de NF-e' : '';
              } else {
                iconSwal = 'error';
                titleSwal = 'Ops!';
                linkRedirect = '/history/nfe';
                msgAdicional = `<p class='f-18'>Problema ao localizar o certificado digital.</p>`;
                textoBotaoRedirect = existeHistoricoNfe === true ? 'Histórico de NF-e' : '';
              }

              // } else if (codigoRet === '138') {
              //   iconSwal = 'success';
              //   titleSwal = 'Pronto!';
              //   linkRedirect = '/history/nfe';
              //   msgAdicional = `<small><a href=${linkSaibaMais} target="_blank">Saiba mais sobre a consulta aqui.</a></small>`;
              //   textoBotaoRedirect = existeHistoricoNfe === true ? 'Histórico de NF-e' : '';
              // } else {
              //   linkRedirect = '/history/nfe';
              //   msgAdicional = `<small><a href=${linkSaibaMais} target="_blank">Saiba mais sobre a consulta aqui.</a></small>`;
              //   textoBotaoRedirect = existeHistoricoNfe === true ? 'Histórico de NF-e' : '';
              // }

              Swal.fire({
                title: titleSwal,
                icon: iconSwal,
                html: msgAdicional,
                showCancelButton: true,
                cancelButtonText: 'Fechar',
                confirmButtonText: 'Voltar',
                confirmButtonColor: '#2ed8b6',
                denyButtonColor: '#4099ff',
                denyButtonText: textoBotaoRedirect,
                showDenyButton: ExibeRedrecionar,
              }).then((result) => {
                if (result.isConfirmed) {
                  dispatch({type: SEQPESQNFE, seqPesqNfe: -1});
                  dispatch({type: ESPIONAR_SEFAZ, espionarSefaz: true});
                } else if (result.isDenied) {
                  //Verifica se achou notas, ai armazena a sequencia
                  if (textoBotaoRedirect.length > 0) {
                    dispatch({ type: SEQPESQNFE, seqPesqNfe: [seq, empselecionada.value] });
                    dispatch({type: UPDATEDATAINI, updateDataIni: true});
                  } else {
                     dispatch({type: SEQPESQNFE, seqPesqNfe: -1});
                  }
                  history.push(linkRedirect);
                }
              });

              dispatch({type: ESPIONAR_SEFAZ, espionarSefaz: false});
            })
            .catch((error) => {
              Swal.fire({
                title: 'Ops!',
                icon: 'error',
                text: 'Problemas ao realizar a consulta. Tente novamente mais tarde.',
              });
              dispatch({type: ESPIONAR_SEFAZ, espionarSefaz: false});
              setEspionando(false);
              setEnablePesquisar(true);
            });
        }
        //=====================================================Cte
        else if (tipoDocCons === '1') {
          handleEspionarCte();
        } else if (tipoDocCons === '2') {
          if (rbNFSe === -1) {
            Swal.fire({
              title: 'Ops!',
              icon: 'info',
              html: 'Selecione o tipo de NFSe para continuar.',
              target: '.modal-body',
            });
            return;
          }
          handleEspionarNfse();
          //dispatch({type: ESPIONAR_SEFAZ, espionarSefaz: false});
          // Swal.fire({
          //   title: 'Ops!',
          //   icon: 'error',
          //   html: 'Em breve...',
          // }).then(() => {
          //   dispatch({type: ESPIONAR_SEFAZ, espionarSefaz: true});
          // });
        }
        const opt = [tipoDocCons, rbPeriodo, rbTomador, rbNsu, txtNsuValue, empselecionada.value];
      }
    }
  };

  const handleEspionarNfse = async () => {
    setEspionando(true);
    setEnablePesquisar(false);

    // rbNfse = 1 = nacional, 0 = municipal
    // empselecionada.cnpj_cpf = cnpj da empresa
    // rbNsuNfse = 0 = Último NSU, 1 = A partir do NSU
    // txtNsuValueNfse = NSU digitado
    // periodoNfse[0] = data inicial
    // periodoNfse[1] = data final
    // inscricaoMunicipal = inscrição municipal
    // tipoServico.value = 1 = Prestados, 2 = Tomados

    const im = inscricaoMunicipal !== null ? inscricaoMunicipal.replace(/[^0-9]/g, '').substring(0, 20) : '';

    const usertxt = document.getElementById('txtUsuarioMunicipio');
    const senhatxt = document.getElementById('txtSenhaMunicipio');
    let UsuarioMun = '';
    let SenhaMun = '';
    

    if (usertxt !== undefined && usertxt !== null) {
      UsuarioMun = usertxt.value;
    }

    if (senhatxt !== undefined && senhatxt !== null) { 
      SenhaMun = senhatxt.value;
    }


    const dataPost = [rbNFSe, empselecionada.cnpj_cpf, rbNsuNfse, txtNsuValueNfse, periodoNfse[0], periodoNfse[1], im, tipoServico.value, selectedMunicipio.value, UsuarioMun, SenhaMun];
    
    
    

    const dataUltima = moment(empselecionada.ultima_consulta_nfse);
    
    

    const dataUltimaNfse = moment(empselecionada.ultima_consulta_nfse).add(1, 'hour').add(1, 'minute');
    
    

    const agora = moment();

    let break_ = false;

    

    if (agora.isBefore(dataUltima)) {      
      //dispatch({type: ESPIONAR_SEFAZ, espionarSefaz: false});
      await Swal.fire({
        title: 'Alerta de consulta rescente',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#23bd9e',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        target: '.modal-body',
        html: `Foi realizada recentemente (menos de 1h atrás) uma consulta para essa empresa às ${dataUltima.format('HH:mm')}.<br/><br/>
                  <span class="f-w-600">RECOMENDAÇÃO</span><br/>
                  Fazer nova consulta a partir das ${dataUltimaNfse.format('HH:mm')}<br/><br/>
                  Deseja aguardar o horário recomendado?`,
      }).then((result) => {
        if (result.isConfirmed) {
          setEnablePesquisar(true);
          break_ = true;
        }
        setEspionando(false);
      });
    }

    if (break_) {
      return;
    }

    setEspionando(true);

    axios
      .post('/api/consultas/', {dest: 'espionarNfseNaA1', dataPost})
      .then((response) => {
        
        
        let motivoRet = response.data;

        let linkRedirect = '';

        let textoBotaoRedirect = '';
        setEspionando(false);
        setEnablePesquisar(true);
        if (motivoRet[0] === 138 || motivoRet[0] === '138') {
          linkRedirect = '/history/nfse';
          Swal.fire({
            title: 'Pronto!',
            icon: 'success',
            html: motivoRet[1],
            showCancelButton: true,
            cancelButtonText: 'Fechar',
            confirmButtonText: 'Voltar',
            confirmButtonColor: '#2ed8b6',
            denyButtonColor: '#4099ff',
            denyButtonText: 'Histórico de NFSe',
            showDenyButton: true,
            target: '.modal-body',
          }).then((result) => {
            if (result.isConfirmed) {
            } else if (result.isDenied) {
              history.push(linkRedirect);
            }
            dispatch({type: ESPIONAR_SEFAZ, espionarSefaz: false});
          });
        } else {
          linkRedirect = '/history/nfse';
          let motivo = motivoRet[1];
          
          // motivoRet =
          //   motivoRet[0] === '000' || motivoRet[0] === '137'
          //     ? `
          //   <span>Não localizamos documentos no período selecionado.</span>
          //   <span
          //     class='mdi mdi-information text-primary ml-2 pointer'
          //     data-toggle="tooltip"
          //     data-placement="top"
          //     title="${motivoRet[1]}"
          //   />
          //   `
          //     : `
          //   <span>Não foi possível completar a consulta.</span>
          //   <span
          //     class='mdi mdi-information text-primary ml-2 pointer'
          //     data-toggle="tooltip"
          //     data-placement="top"
          //     title="${motivoRet[1]}"
          //   />
          //   `;

          if (motivoRet[0] === '000' || motivoRet[0] === '137' || motivoRet[0] === 0 || motivoRet[0] === '0') {
            motivoRet = `
            <span>Não localizamos documentos no período selecionado.</span>
            <button class="btn btn-link p-0 text-primary" id='btn-info-NSFe'>Saiba mais</button>
          `;
          } else {
            if (motivoRet[1].indexOf('5 minutos') < -1) {
              motivoRet = `
              <span>Foi efetuada uma consulta recente, aguarde 5 minutos para poder realizar outra consulta!</span></br>
              <button class="btn btn-link p-0 text-primary" id='btn-info-NSFe'>Saiba mais</button>`;
            } else {
              if (motivoRet[1].indexOf('NFS-e não encontrada') > -1) {
                motivoRet = `
              <span>Não localizamos documentos no período selecionado.</span></br>
              <button class="btn btn-link p-0 text-primary" id='btn-info-NSFe'>Saiba mais</button>`;
              }
              motivoRet = `
            <span>Não foi possível completar a consulta.</span></br>
            <button class="btn btn-link p-0 text-primary" id='btn-info-NSFe'>Saiba aqui o motivo</button>`;
            }
          }
          // motivoRet =
          //   motivoRet[0] === '000' || motivoRet[0] === '137' || motivoRet[0] === '0'
          //     ? `
          //   <span>Não localizamos documentos no período selecionado.</span>
          //   <button type="button" class="btn btn-link p-0" data-toggle="tooltip" data-placement="top" title="${motivoRet[1]}">Saiba mais</button>
          // `
          //     : `
          //   <span>Não foi possível completar a consulta.</span></br>
          //   <button class="btn btn-link p-0 text-primary" id='btn-info-NSFe'>Saiba mais</button>
          // `;

          setTimeout(() => {
            // Log do valor do motivo
            let id = document.getElementById('btn-info-NSFe');
            if (id) {
              // Confirma que o botão foi encontrado
              id.addEventListener('click', () => {
                Swal.fire({
                  title: 'Detalhes da consulta',
                  icon: 'info',
                  html: `<p>${motivo}</p>`,
                  target: '.modal-body',
                  confirmButtonText: 'Fechar',
                  confirmButtonColor: '#297BEA',
                });
              });
            } else {
              // Log se o botão não for encontrado
            }
          }, 500); // Aumentado o delay para 500ms

          // motivoRet = motivoRet.replace(/(?:\r\n|\r|\n)/g, '<br>');
          // motivoRet = motivoRet.replace('na API', 'no Sistema');
          Swal.fire({
            icon: 'info',
            html: motivoRet,
            showCancelButton: true,
            cancelButtonText: 'Fechar',
            confirmButtonText: 'Voltar',
            confirmButtonColor: '#2ed8b6',
            denyButtonColor: '#4099ff',
            denyButtonText: textoBotaoRedirect,
            showDenyButton: textoBotaoRedirect.length > 0 ? true : false,
            target: '.modal-body',
          }).then((result) => {
            if (result.isConfirmed) {
              dispatch({type: ESPIONAR_SEFAZ, espionarSefaz: true});
            } else if (result.isDenied) {
              history.push(linkRedirect);
            }
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: 'Ops!',
          icon: 'error',
          html: 'Ocorreu um erro ao realizar a consulta. Tente novamente.',
          target: '.modal-body',
        });

        setEspionando(false);
        setEnablePesquisar(true);
      });
  };

  const handleEspionarCte = async () => {
    setEspionando(true);
    setEnablePesquisar(false);

    const dataPost = [rbPeriodoCte, rbTomador, rbNsuCte, txtNsuValueCte, empselecionada.cnpj_cpf];
    const dataUltima = moment(empselecionada.ultima_consulta_cte);
    const dataUltimaCte = moment(empselecionada.ultima_consulta_cte).add(1, 'hour').add(1, 'minute');
    const agora = moment();
    let break_ = false;

    if (agora.isBefore(dataUltimaCte)) {
      //dispatch({type: ESPIONAR_SEFAZ, espionarSefaz: false});
      await Swal.fire({
        title: 'Alerta de consulta rescente',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#23bd9e',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        target: '.modal-body',
        html: `Foi realizada recentemente (menos de 1h atrás) uma consulta para essa empresa às ${dataUltima.format('HH:mm')}.<br/><br/>
                  <span class="f-w-600">RECOMENDAÇÃO</span><br/>
                  Fazer nova consulta a partir das ${dataUltimaCte.format('HH:mm')}<br/><br/>
                  Deseja aguardar o horário recomendado?`,
      }).then((result) => {
        if (result.isConfirmed) {
          setEspionando(false);
          setEnablePesquisar(true);
          break_ = true;
        }
      });
    }

    if (break_) {
      return;
    }

    setEspionando(true);

    axios
      .post('/api/consultas/', {dest: 'espionarCteA1', dataPost})
      .then((response) => {
        const {cod: codigoRet, motivo: motivoRet} = response.data;


        let linkRedirect = '';
        let msgAdicional = '';
        let textoBotaoRedirect = '';
        let iconSwal = '';
        let titleSwal = '';

        let linkConsumoIndevido = 'https://www.espiaonfe.com.br/lab/consumo-indevido-conheca-as-regras-de-consulta-nfe-da-sefaz/';
        let linkSaibaMais = 'https://www.espiaonfe.com.br/lab/quais-sao-as-regras-para-consultar-nfe-na-sefaz/';
        let linkCertificadoDigital = 'https://www.espiaonfe.com.br/lab/?i=o-que-e-certificado-digital-e-para-que-serve-conheca-as-principais-duvidas';

        let existeHistoricoCte = false;
        let existeMenuCertificados = false;

        switch (codigoRet) {
          case '000':
            iconSwal = 'error';
            titleSwal = 'Ops!';
            linkRedirect = '/certificados';
            msgAdicional = `
            <p class='f-18'>${motivoRet}</p>
            <small><a href=${linkCertificadoDigital} target="_blank">Saiba mais sobre o certificado digital aqui.</a></small>`;
            textoBotaoRedirect = existeMenuCertificados ? 'Gerenciar Certificados' : '';
            break;
          case '001':
            iconSwal = 'error';
            titleSwal = 'Ops!';
            linkRedirect = '/certificados';
            msgAdicional = `
            <p class='f-18'>${motivoRet}</p>
            <small><a href=${linkCertificadoDigital} target="_blank">Saiba mais sobre o certificado digital aqui.</a></small>`;
            textoBotaoRedirect = existeMenuCertificados ? 'Gerenciar Certificados' : '';
            break;
          case '002':
            linkRedirect = '/certificados';
            msgAdicional = `
            <p class='f-18'>${motivoRet}</p>
            <small><a href=${linkCertificadoDigital} target="_blank">Saiba mais sobre o certificado digital aqui.</a></small>`;
            textoBotaoRedirect = existeMenuCertificados ? 'Gerenciar Certificados' : '';
            break;
          case '003':
          case '004':
          case '006':
          case '007':
          case '137':
            iconSwal = 'error';
            titleSwal = 'Ops!';
            linkRedirect = '/history/cte';
            msgAdicional = `
             <p class='f-18'>${motivoRet}</p>
            <small><a href=${linkSaibaMais} target="_blank">Saiba mais sobre a consulta aqui.</a></small>`;
            textoBotaoRedirect = existeHistoricoCte ? 'Histórico de CT-e' : '';
            break;
          case '656':
            iconSwal = 'error';
            titleSwal = 'Ops!';
            msgAdicional = `<p class='f-18'>${motivoRet}</p>
                            <small><a href=${linkConsumoIndevido} target="_blank">Saiba mais sobre o consumo indevido aqui.</a></small>`;
            textoBotaoRedirect = existeHistoricoCte ? 'Histórico de CT-e' : '';
            break;
          case '593':
            iconSwal = 'error';
            titleSwal = 'Ops!';
            msgAdicional = `<p class='f-18'>${motivoRet}</p>`;
            textoBotaoRedirect = existeHistoricoCte ? 'Histórico de CT-e' : '';
            break;
          case '138':
            iconSwal = 'success';
            titleSwal = 'Pronto!';
            linkRedirect = '/history/cte';
            msgAdicional = `
            <p class='f-18'>${motivoRet}</p>
            <small><a href=${linkSaibaMais} target="_blank">Saiba mais sobre a consulta aqui.</a></small>`;
            textoBotaoRedirect = existeHistoricoCte ? 'Histórico de CT-e' : '';
            break;
          default:
            linkRedirect = '/history/cte';
            msgAdicional = `<small><a href=${linkSaibaMais} target="_blank">Saiba mais sobre a consulta aqui.</a></small>`;
            textoBotaoRedirect = existeHistoricoCte ? 'Histórico de CT-e' : '';
        }

        Swal.fire({
          title: titleSwal,
          icon: iconSwal,
          html: msgAdicional,
          showCancelButton: true,
          cancelButtonText: 'Fechar',
          confirmButtonText: 'Voltar',
          confirmButtonColor: '#2ed8b6',
          denyButtonColor: '#4099ff',
          denyButtonText: textoBotaoRedirect,
          showDenyButton: textoBotaoRedirect.length > 0,
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch({type: ESPIONAR_SEFAZ, espionarSefaz: true});
          } else if (result.isDenied) {
            history.push(linkRedirect);
          }
        });

        dispatch({type: ESPIONAR_SEFAZ, espionarSefaz: false});
      })
      .catch((error) => {
        Swal.fire({
          title: 'Ops!',
          icon: 'error',
          text: 'Problemas ao realizar a consulta. Tente novamente mais tarde.',
        });
        dispatch({type: ESPIONAR_SEFAZ, espionarSefaz: false});
        setEspionando(false);
        setEnablePesquisar(true);
      });
  };

  // const handleTrataDataIni = (dados) => {
  //   setselectEmpresa(dados);
  //   setEnablePesquisar(true);
  //   setempselecionada({value: 0, label: 'Selecione uma empresa'});

  //   let empSelecionadaStorage = localStorage.getItem('empDoc');

  //   if (empSelecionadaStorage !== undefined && empSelecionadaStorage !== null) {
  //     empSelecionadaStorage = JSON.parse(base64_decode(empSelecionadaStorage));

  //     const filtered = dados.filter((obj) => {
  //       return obj.value === empSelecionadaStorage.value;
  //     });
  //     if (filtered.length > 0) {
  //       handleChangeEmpresa(filtered[0]);
  //       setempselecionada(filtered[0]);
  //       setTipoCertificado(filtered[0].tipo === null ? -1 : filtered[0].tipo);
  //       setDataHoraNfe(filtered[0].ultima_consulta_nfe);
  //     }
  //   } else {
  //     setempselecionada(dados[0]);
  //     handleChangeEmpresa(dados[0]);
  //   }
  // };

  function handleKeyUpNSU(e) {
    const nsu = e.target.value.replace(/[^0-9]/g, '');
    setTxtNsuValue(nsu);
  }

  function handleKeyUpNSUCte(e) {
    const nsu = e.target.value.replace(/[^0-9]/g, '');
    setTxtNsuValueCte(nsu);
  }

  function handleKeyUpNSUNfse(e) {
    const nsu = e.target.value.replace(/[^0-9]/g, '');
    setTxtNsuValueNfse(nsu);
  }

  useEffect(() => {
    if (configContext.state.tipoConsulta !== '-1') {
      const e = {target: {value: configContext.state.tipoConsulta}};
      handleTipoDoc(e);
      // setTimeout(() => {
      //   if (parseInt(tipoCertificado) === 0) {
      //     handleEspionar();
      //   }
      // }, 1200);

      dispatch({type: TIPOCONSULTA, tipoConsulta: '-1'});
    }
    const fetchData = async () => {
      const response = await axios.post('/api/initData/', {dest: 'dataIni'});
      const data_ = await response.data;
      if (data_.length === 0) {
        setselectEmpresa([]);
        Swal.fire({
          title: 'Ops!',
          icon: 'error',
          html: 'Você ainda não possui empresas para Espionar.',
        });
        dispatch({type: ESPIONAR_SEFAZ, espionarSefaz: false});
        return;
      }
      if (data_ === false) {
        setselectEmpresa([]);
        return;
      }
      setselectEmpresa(data_);
      handleChangeEmpresa(TrataDataIniEmpresas(data_));
    };

    const verificaPermissao = async () => {
      const res = await axios.get('/api/users/', {dest: 'carregaMenu'});

      const data = res.data;
      const find_ = data.items.find((item) => item.id === '19');

      if (find_) {
        const findNfe = find_.children.find((item) => item.id === '32');
        const findCte = find_.children.find((item) => item.id === '37');
        const findNfse = find_.children.find((item) => item.id === '38');

        if (findNfe) {
          setArrayPermiteEspionarConta((prevState) => ({...prevState, nfe: 't'}));
        } else {
          setArrayPermiteEspionarConta((prevState) => ({...prevState, nfe: 'f'}));
        }

        if (findCte) {
          setArrayPermiteEspionarConta((prevState) => ({...prevState, cte: 't'}));
        } else {
          setArrayPermiteEspionarConta((prevState) => ({...prevState, cte: 'f'}));
        }

        if (findNfse) {
          setArrayPermiteEspionarConta((prevState) => ({...prevState, nfse: 't'}));
        } else {
          setArrayPermiteEspionarConta((prevState) => ({...prevState, nfse: 'f'}));
        }
      } else {
        setArrayPermiteEspionarConta((prevState) => ({...prevState, nfe: 'f', cte: 'f', nfse: 'f'}));
      }
      setEnablePesquisar(true);
      if (configContext.state.tipoConsulta !== '-1') {
        // const e = {target: {value: configContext.state.tipoConsulta}};
        //handleTipoDoc(e);
        setTimeout(() => {
          if (parseInt(tipoCertificado) === 0) {
            handleEspionar();
          }
        }, 700);

        dispatch({type: TIPOCONSULTA, tipoConsulta: '-1'});
      }

      const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
      const users = JSON.parse(usersRt);

      //NFe === '32' || Cte === '37' || NFSe === '38');
      const FindMenuNFe = users[26].find((item) => item.key === '32');
      const FindMenuCte = users[26].find((item) => item.key === '37');
      const FindMenuNfse = users[26].find((item) => item.key === '38');

      if (FindMenuNFe !== undefined) {
        setArrayPermiteEspionarConta((prevState) => ({...prevState, nfe: 'f'}));
      } else if (FindMenuCte !== undefined) {
        setArrayPermiteEspionarConta((prevState) => ({...prevState, cte: 'f'}));
      } else if (FindMenuNfse !== undefined) {
        setArrayPermiteEspionarConta((prevState) => ({...prevState, nfse: 'f'}));
      }
      return;
    };
    //if (configContext.state.updateDataIni === true) {

    verificaPermissao();
    fetchData();

    // }
  }, []);

  return (
    <Modal
      id='modalEspionar'
      size='xl'
      backdrop='static'
      dialogClassName='overflow-auto'
      show={configContext.state.espionarSefaz}
      onHide={() => {
        dispatch({type: ESPIONAR_SEFAZ, espionarSefaz: false});
      }}
    >
      <Modal.Body>
        <h5 className='text-center mt-1 mb-3'>
          <span className='fa fa-search fa-2x text-c-blue'></span>
        </h5>
        <h5 className='text-center mb-0'>Consultar na Sefaz ou nas Prefeituras</h5>
        <hr className='mb-3' />
        <Row className='p-1'>
          <Col
            sm={12}
            className='text-left align-itens-center'
          >
            <Form.Label>Empresas</Form.Label>
            <Select
              className='basic-single tour-1'
              classNamePrefix='select'
              name='empresas'
              placeholder='Carregando empresas...'
              isDisabled={!enablePesquisar}
              value={empselecionada}
              options={selectEmpresa}
              onChange={(e) => handleChangeEmpresa(e)}
            />
            <div className='p-1'>
              {tipoDocCons === '0' && <small>Última consulta de NF-e: {dataHoraNfe !== null ? dataHoraNfe : <span className='text-danger'>Nunca consultado</span>}</small>}
              {tipoDocCons === '1' && <small>Última consulta de CT-e: {dataHoraCte}</small>}
              {tipoDocCons === '2' && rbNFSe === '1' && <small>Última consulta de NFS-e (SEFAZ Nacional): {dataHoraNfse !== null ? dataHoraNfse : <span className='text-danger'>Nunca consultado</span>}</small>}
            </div>
          </Col>
        </Row>

        {blqInterno === 'f' || blqInterno === false || blqInterno === 'false' ? (
          tipoCertificado !== -1 ? (
            enablePesquisar === true ? (
              <>
                <Row className='justify-content-center mt-3'>
                  <Col sm={12}>
                    <Row>
                      <Col
                        sm={6}
                        className=''
                      >
                        <div className='ml-3'>
                          <strong className='ml-4'>Qual documento gostaria de consultar agora?</strong>
                          <Form.Group
                            className='tipoDoc'
                            checked={tipoDocCons}
                          >
                            <Form.Check
                              custom
                              style={{zIndex: 0}}
                              value={'0'}
                              className='mt-3 rb-w w-100 nfe'
                              type='radio'
                              checked={tipoDocCons === '0' && arrayPermiteEspionar.nfe === 't' && arrayPermiteEspionarConta.nfe === 't' ? true : false}
                              disabled={arrayPermiteEspionar.nfe === 'f' || arrayPermiteEspionarConta.nfe === 'f'}
                              onChange={handleTipoDoc}
                              label={
                                <Card
                                  className={'mb-1 pointer mt-0 ' + bgSelectNfe}
                                  id='card-0'
                                >
                                  <Card.Body className='p-3 mt-0'>
                                    <strong>NF-e </strong> {arrayPermiteEspionar.nfe === 'f' || arrayPermiteEspionarConta.nfe === 'f' ? <small className='text-danger f-w-400'> - Não disponível</small> : ''}
                                    <br />
                                    <small>Nota Fiscal Eletrônica</small>
                                  </Card.Body>
                                </Card>
                              }
                              name='radioCons'
                              id='radioConsNfe'
                            />
                            <Form.Check
                              custom
                              value={'1'}
                              className='mt-3 rb-w w-100 cte'
                              type='radio'
                              checked={tipoDocCons === '1' && arrayPermiteEspionar.cte === 't' && arrayPermiteEspionarConta.cte === 't' ? true : false}
                              disabled={arrayPermiteEspionar.cte === 'f' || arrayPermiteEspionarConta.cte === 'f'}
                              style={{zIndex: 0}}
                              onChange={handleTipoDoc}
                              label={
                                <Card
                                  className={'mb-1 pointer mt-0 ' + bgSelecCte}
                                  id='card-1'
                                >
                                  <Card.Body className='p-3 mt-0'>
                                    <strong>CT-e </strong> {arrayPermiteEspionar.cte === 'f' || arrayPermiteEspionarConta.cte === 'f' ? <small className='text-danger f-w-400'> - Não disponível</small> : ''}
                                    <br />
                                    <small>Conhecimento de Transporte Eletrônico</small>
                                  </Card.Body>
                                </Card>
                              }
                              name='radioCons'
                              id='radioConsCte'
                            />
                            <Form.Check
                              custom
                              value={'2'}
                              style={{zIndex: 0}}
                              className='mt-3 rb-w w-100 nfse'
                              type='radio'
                              checked={tipoDocCons === '2' && arrayPermiteEspionar.nfse === 't' && arrayPermiteEspionarConta.nfse === 't' ? true : false}
                              disabled={arrayPermiteEspionar.nfse === 'f' || arrayPermiteEspionarConta.nfse === 'f' || empselecionada.cnpj_cpf === '00000000000001'}
                              onChange={handleTipoDoc}
                              label={
                                <Card
                                  className={'mb-1 pointer mt-0 ' + bgSelectNfse}
                                  id='card-2'
                                >
                                  <Card.Body className='p-3 mt-0'>
                                    <strong>NFS-e </strong> {empselecionada.cnpj_cpf === '00000000000001' ? <small className='text-danger f-w-400'> - Não disponível para dados fictícios</small> : arrayPermiteEspionar.nfse === 'f' || arrayPermiteEspionarConta.nfse === 'f' ? <small className='text-danger f-w-400'> - Não disponível</small> : ''}
                                    <br />
                                    <small>Nota Fiscal de Serviço Eletrônica</small>
                                  </Card.Body>
                                </Card>
                              }
                              name='radioCons'
                              id='radioConsNfse'
                            />
                          </Form.Group>
                        </div>
                      </Col>
                      <Col sm={6}>
                        {parseInt(tipoCertificado) === 0 && tipoDocCons !== '-1' ? (
                          <strong>
                            Opções da consulta{' '}
                            {tipoDocCons !== '2' && (
                              <a
                                className='badge badge-light-primary pointer'
                                onClick={() => setShowOptConsulta(!showOptConsulta)}
                              >
                                <span className='fa fa-cog'></span>
                              </a>
                            )}
                          </strong>
                        ) : (
                          <>
                            {tipoDocCons !== '2' && !espionarA3 && (
                              <Col>
                                <h6 className='text-center'>Selecione ao lado qual documento deseja consultar.</h6>
                              </Col>
                            )}
                          </>
                        )}
                        {
                          /* Opções de consulta NFE */
                          tipoDocCons === '0' ? (
                            parseInt(tipoCertificado) === 0 ? (
                              <OpcoesNfe
                                rbManif={rbManif}
                                setRbManif={setRbManif}
                                showOptConsulta={showOptConsulta}
                                arrStrg={arrStrg}
                                empselecionada={empselecionada}
                                setRbPeriodo={setRbPeriodo}
                                rbNsu={rbNsu}
                                setRbNsu={setRbNsu}
                                txtNsuValue={txtNsuValue}
                                setTxtNsuValue={setTxtNsuValue}
                                handleKeyUpNSU={handleKeyUpNSU}
                                setAlertNfe={setAlertNfe}
                              />
                            ) : (
                              espionarA3
                            )
                          ) : /* Opções de consulta CTE */ tipoDocCons === '1' ? (
                            parseInt(tipoCertificado) === 0 ? (
                              <OpcoesCte
                                rbTomador={rbTomador}
                                setRbTomador={setRbTomador}
                                showOptConsulta={showOptConsulta}
                                arrStrg={arrStrg}
                                empselecionada={empselecionada}
                                setRbPeriodoCte={setRbPeriodoCte}
                                rbNsuCte={rbNsuCte}
                                setRbNsuCte={setRbNsuCte}
                                txtNsuValueCte={txtNsuValueCte}
                                setTxtNsuValueCte={setTxtNsuValueCte}
                                handleKeyUpNSUCte={handleKeyUpNSUCte}
                              />
                            ) : (
                              espionarA3
                            )
                          ) : (
                            tipoDocCons === '2' && (
                              /* Opções de consulta NFSE */
                              <OpcoesNfse
                                rbNFSe={rbNFSe}
                                setRbNFSe={setRbNFSe}
                                periodoNfse={periodoNfse}
                                setPeriodoNfse={setPeriodoNfse}
                                inscricaoMunicipal={inscricaoMunicipal}
                                setInscricaoMunicipal={setInscricaoMunicipal}
                                empSelecionada={empselecionada}
                                rbNsuNfse={rbNsuNfse}
                                setRbNsuNfse={setRbNsuNfse}
                                txtNsuValueNfse={txtNsuValueNfse}
                                setTxtNsuValueNfse={setTxtNsuValueNfse}
                                handleKeyUpNSUNfse={handleKeyUpNSUNfse}
                                tipoServico={tipoServico}
                                setTipoServico={setTipoServico}
                                selectedMunicipio={selectedMunicipio}
                                setSelectedMunicipio={setSelectedMunicipio}
                              />
                            )
                          )
                        }
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            ) : espionando === false ? (
              <h4 className='text-center'>
                <span className='fa fa-spinner fa-spin'></span> Carregando empresas, aguarde!
              </h4>
            ) : (
              <Row>
                <Col>
                  <h5 className='text-center'>
                    <img
                      src={procurando2}
                      alt='Procurando'
                    />
                    <br />
                    Espionando {tipoDocCons === '0' ? 'NF-e' : tipoDocCons === '1' ? 'CT-e' : `NFS-e ${tipoServico.label} em ${rbNFSe === '0' ? selectedMunicipio.label : ' Ambiente Nacional'}`}, aguarde!
                  </h5>
                </Col>
              </Row>
            )
          ) : (
            <Row>
              <Col
                sm={12}
                className='text-center'
              >
                <img
                  src={noData}
                  alt='No data'
                  className='img-fluid'
                  style={{maxWidth: '100%', height: 'auto', opacity: 0.7}}
                />
                <p className='f-w-600 f-14'>Esta empresa ainda não possui certificado Digital vinculado.</p>
                <Button
                  variant='success'
                  className='rounded btn-sm mt-3'
                  onClick={() => {
                    dispatch({type: ESPIONAR_SEFAZ, espionarSefaz: false});
                    history.push('/certificados');
                  }}
                >
                  Cadastrar Certificado A1
                </Button>
                <Button
                  variant='primary'
                  className='rounded btn-sm mt-3 ml-3'
                  onClick={() => {
                    dispatch({type: ESPIONAR_SEFAZ, espionarSefaz: false});
                    history.push('/empresas');
                  }}
                >
                  Vincular Certificado A3
                </Button>
              </Col>
            </Row>
          )
        ) : (
          <Row>
            <Col
              sm={12}
              className='text-center'
            >
              <h5 className='text-center'>
                <img
                  src={noData}
                  alt='No data'
                  className='img-fluid'
                  style={{maxWidth: '100%', height: 'auto', opacity: 0.7}}
                />
                <br />
              </h5>
              <span className='f-w-600'>
                Esta empresa está indisponível para consultas por motivo de duplicidade, <br />
                <a
                  className='rounded btn-sm text-decoration-none'
                    href={'https://tawk.to/appespiaocloud'}
                > 
                  Liberar acesso!
                </a>{' '}
              </span>
            </Col>
          </Row>
        )}
        <hr />
        <div className='text-center'>
          {enablePesquisar === true && parseInt(tipoCertificado) === 0 ? (
            tipoDocCons > -1 ? (
              <Button
                variant='success'
                onClick={() => handleEspionar()}
                className='rounded ml-5 tour-2'
                disabled={!enablePesquisar}
              >
                <span className='fa fa-search mr-1'></span> Espionar documentos
              </Button>
            ) : (
              <Button
                variant='warning'
                className='rounded ml-5 tour-2'
                disabled
              >
                <span className='fa fa-ban mr-1'></span> Selecione o tipo de documento
              </Button>
            )
          ) : (
            tipoDocCons === '2' && (
              <Button
                variant='success'
                onClick={() => handleEspionar()}
                className='rounded ml-5 tour-2'
                disabled={!enablePesquisar}
              >
                <span className='fa fa-search mr-1'></span> Espionar documentos
              </Button>
            )
          )}

          <Button
            variant='secondary'
            className='float-right'
            onClick={() => dispatch({type: ESPIONAR_SEFAZ, espionarSefaz: false})}
            disabled={espionando}
          >
            Fechar
          </Button>
        </div>

        {/* <Joyride
          callback={handleJoyrideCallback}
          run={run}
          styles={{
            options: {
              //arrowColor: '#e3ffeb',
              //backgroundColor: '#e3ffeb',
              //overlayColor: 'rgba(79, 26, 0, 0.4)',
              primaryColor: '#297bea',
              //textColor: '#004a14',
              //width: 900,
              zIndex: 10000,
            },
          }}
          continuous
          scrollToFirstStep
          showProgress
          showSkipButton
          disableOverlayClose
          steps={steps}
          // styles={{
          //   options: {
          //     zIndex: 10000,
          //   },
          // }}
        /> */}
      </Modal.Body>
    </Modal>
  );
};

export default ModalEspionar;
